/* Slideshow */
.slideShow *{
    background-position: center;
    padding: 0%;
}

/* Category */
.category {
    display: flex;
    overflow: hidden;
    padding: 3rem;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    background: var(--skinny);
    width: 100%;
    overflow-x: auto;
}

.category div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 13rem;
    width: 12rem;
    padding: 1.25rem;
    border-radius: 6.25rem 6.25rem 2rem 2rem;
    background: var(--Dark-Green, #143A0E);
    flex-direction: column;
    gap: 1rem;
    cursor: pointer;
}

.category img {
    object-fit: cover;
    height: 128px;
    width: 156px;
    border-radius: 8rem 8rem 1rem 1rem;
}

.category p {
    color: var(--skinny);
    font-size: 1.25rem;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    text-wrap: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
}

/* Why */
.Why {
    display: flex;
    padding: 1.125rem 2.25rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    background: var(--dark-green, #143A0E);
    color: var(--skinny);
}

.Why h3 {
    color: var(--skinny);
    text-align: center;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.Why>div {
    display: flex;
    padding: 1rem;
    align-items: center;
    gap: 1rem;
    flex: 1 0 0;
    align-self: stretch;
}

.Why>div>h4 {
    display: flex;
    height: 100%;
    text-align: center;
    padding: 2rem 1rem;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 6.25rem;
    background: var(--skinny, #F4F8CF);
    color: var(--dark-green);
    width: 100%;
}

/* Review */
.Review {
    display: flex;
    padding: 2rem 0rem;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
    background: var(--dark-green, #23581B);
    color: var(--skinny);
    border-top: 4px dashed var(--skinny);
}

.Review>p {
    color: var(--skinny);
    text-align: center;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.Review>div {
    width: 70%;
}

.singleReview {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.singleReview img {
    aspect-ratio: 1 /1;
    border-radius: 100%;
    max-height: 4rem;
}

.singleReview p {
    text-align: center;
    text-overflow: ellipsis;
    text-wrap: balance;
}

@media (max-width: 768px) {
    .Why>div {
        flex-direction: column;
        padding: 0;
    }

    .category {
        padding: 1rem;
    }
}