.Signup{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80svh;
}

.Signup>img{
    width: 70%;
    object-fit: cover;
    height: 100%;
}

.Signup>form{
    padding: 2rem;
    background-color: var(--dark-green);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--skinny);
    width: 30%;
}

.Signup>form>div{
    width: 100%;
}

@media (max-width: 768px) {
    .Signup>img{
        width: 0;
    }
    .Signup>form{
        width: 100%;
    }
}