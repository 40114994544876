.Verification {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: var(--dark-green);
    gap: 2rem;
    position: relative;
    color: var(--skinny);
    min-height: 300px;
    padding: 1rem;
}
.Verification h1{
    text-align: center;
}