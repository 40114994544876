.Search {
    display: flex;
    width: 100%;
}

.sideBar {
    width: max(20%, 300px);
    background: var(--dark-green);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: sticky;
    top: 0;
    bottom: 0;
}

.main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
}

.main>div {
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
    padding: 1rem;
    height: 100%;
    width: 100%;
}

@media (max-width: 768px) {
    .Search {
        flex-direction: column;
    }

    .sideBar {
        flex-direction: row;
        height: fit-content;
        overflow-x: auto;
        width: 100%;
        padding: 0.5rem 1rem;
    }

    .sideBar>div {
        width: 150px;
    }

    .sideBar>div>select {
        width: 150px;
        border-radius: 6.5rem;
        padding: 0.5rem;
    }
}