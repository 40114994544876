.ResetPassword {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80svh;
}

.ResetPassword>img {
    width: 70%;
    object-fit: cover;
    height: 100%;
}

.ResetPassword>form {
    padding: 2rem;
    background-color: var(--dark-green);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--skinny);
    width: 30%;
}

.ResetPassword>form>div {
    width: 100%;
}

@media (max-width: 768px) {
    .ResetPassword>img {
        width: 0;
    }

    .ResetPassword>form {
        width: 100%;
    }
}