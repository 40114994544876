.Return {
    background-color: var(--skinny);
    padding: 2rem;
}

.Return>h3 {
    font-size: 2rem;
    color: var(--dark-green);
}

.Return>div {
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-top: 1rem;
    padding: 0 2rem;
}

.detail {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    border: 4px solid var(--dark-green);
    border-radius: 1.25rem;
    min-width: 250px;
}

.detail>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 768px) {
    .Return{
        padding: 1rem;
    }
    .Return>div {
        flex-direction: column;
        padding: 1rem;
        margin-top: 0;
    }

    .detail {
        width: 100%;
    }
}