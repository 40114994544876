* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Inika", serif;
}

:root {
  --green: #23581B;
  --skinny: #F4F8CF;
  --dark-green: #143A0E;
  --yellow: #FFEE51;
}


.loader {
  width: 48px !important;
  height: 48px !important;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loaderBg {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--dark-green);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.border-btn {
  border-radius: 0.5rem;
  border: 2px solid var(--dark-green, #143A0E);
  color: var(--dark-green, #143A0E);
  background: var(--Skinny, #F4F8CF);
}

.border-btn:hover {
  background: var(--dark-green, #143A0E);
  color: var(--Skinny, #F4F8CF);
}

.border-round-btn {
  border-radius: 6.5rem;
  border: 2px solid var(--dark-green, #143A0E);
  color: var(--dark-green, #143A0E);
  background: var(--Skinny, #F4F8CF);
}

.border-round-btn:hover {
  background: var(--dark-green, #143A0E);
  color: var(--Skinny, #F4F8CF);
}

.primary-btn {
  border-radius: 0.5rem;
  border: 0px;
  color: var(--skinny, #F4F8CF);
  background-color: var(--green);
}

.primary-btn:hover {
  background-color: var(--skinny, #F4F8CF);
  color: var(--green);
}

.primary-round-btn {
  border-radius: 6.25rem;
  border: 0px;
  color: var(--Skinny, #F4F8CF);
  background-color: var(--green);
}

.primary-round-btn:hover {
  background-color: var(--skinny, #F4F8CF);
  color: var(--green);
}

.secondary-btn {
  border-radius: 0.5rem;
  border: 0px;
  background: var(--skinny, #F4F8CF);
  color: var(--green)
}

.secondary-btn:hover {
  color: var(--skinny, #F4F8CF);
  background-color: var(--green)
}

.secondary-round-btn {
  border-radius: 6.25rem;
  border: 0px;
  background: var(--Skinny, #F4F8CF);
  color: var(--green)
}

.secondary-round-btn:hover {
  color: var(--skinny, #F4F8CF);
  background-color: var(--green)
}

button {
  cursor: pointer;
  display: inline-flex;
  padding: 0.5rem 0.6rem;
  justify-content: center;
  align-items: center;
  transition: all 200ms;
  font-weight: 700;
  font-size: 1.15rem;
  text-wrap: nowrap;
}

input,
textarea,
select {
  width: 100%;
  background-color: var(--skinny);
  padding: 0.75rem 1rem;
  color: #143A0E;
  font-size: 1rem;
  border: 0.125rem solid var(--dark-green);
  border-radius: 0.5rem;
  font-weight: 700;
}

textarea {
  height: 150px;
  text-transform: none;
  resize: none;
}

input:focus,
textarea:focus,
select:focus {
  outline-style: none;
  box-shadow: none;
  border-color: none;
}

form span {
  font-size: 0.75rem;
  color: #ff6969;
  font-weight: 700;
}

form label {
  font-weight: 700;
}

form input {
  border-radius: .5rem;
}

form button {
  width: 100%;
}

a{
  all: unset;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}