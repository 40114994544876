.NavBar {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--green);
    padding: 1rem 2.5%;
    position: relative;
}

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 200px;
    background-color: var(--dark-green);
    transition: all 300ms;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0.5rem;
    border-left: 2px solid black;
    z-index: 9999;
    gap: 2rem;
    overflow-y: auto;
}

.showSidebar {
    right: 0;
}

.hideSidebar {
    right: -200px;
}

.sidebar>div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.sidebar button{
    width: 100%;
    text-wrap: balance;
}

.show {
    display: none !important;
}

.NavBar img {
    max-height: 96px;
    max-width: 75%;
    cursor: pointer;
    padding: 8px;
}

.MainArea {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
}

.MainArea>div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    padding: 1rem;
}

.SearchArea {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.25rem;
}

.SearchArea input {
    height: 100%;
    border-radius: 6.5rem 0 0 6.5rem;
}

.SearchArea button {
    height: 100%;
    border-radius: 0 6.5rem 6.5rem 0;
}

.SearchArea svg {
    color: var(--dark-green);
}

.MainArea svg {
    font-size: 1.5rem;
    color: var(--skinny);
    cursor: pointer;
}

@media (max-width:768px) {
    .hide {
        display: none !important;
    }

    .show {
        display: block !important;
    }
}