.Admin{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
}

.Admin>div{
    background-color: var(--green);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    border-radius: 1rem;
    gap: 1rem
}

.Admin button{
    width: 100%;
}

@media (max-width: 768px) {
    .ManageProduct {
        padding: 0.5rem;
    }
}
