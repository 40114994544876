.Order {
    background-color: var(--skinny);
    padding: 2rem;
}

.Order>h3 {
    font-size: 2rem;
    color: var(--dark-green);
}

.OrderDetail {
    background-color: var(--skinny);
    padding: 2rem;
}

.OrderDetail>h3 {
    font-size: 2rem;
    color: var(--dark-green);
}

.Order>div {
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-top: 1rem;
    padding: 0 2rem;
}

.OrderDetail>div {
    width: 100%;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1.5rem;
    border: 4px solid var(--dark-green);
    min-height: 50vh;
}

.imagePart {
    display: flex;
    flex-direction: column;
    border: 4px solid var(--dark-green);
    border-radius: 1.5rem;
    overflow: hidden;
    gap: 1rem;
    width: 100%;
    justify-content: space-between;
}

.imagePart>div:nth-child(1) {
    padding: 1rem;
    ;
}

.imagePart>div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6.5rem;
}

.imagePart>div:nth-child(2){
    border: 4px solid var(--dark-green);
    border-radius: 5rem;
    overflow: hidden;
}

.imagePart>div>button {
    width: 100%;
    height: 100%;
    border-radius: 0;
    border: none;
}


.OrderDetail ul {
    margin-left: 1.5rem;
}

.OrderDetail li {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    gap: 1rem;
}

.OrderDetail>div h3 {
    width: 150px;
}

.OrderDetail>div h4 {
    width: calc(100% - 150px);
}

.OrderDetail>div>div {
    width: 100%;
    display: flex;
    gap: 1rem;
}

.detailPart {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .Order>div {
        flex-direction: column;
        padding: 1rem;
    }

    .Order {
        padding: 1rem;
    }

    .OrderDetail>div {
        flex-direction: column;
        padding: 1rem;
    }

    .OrderDetail>div>div {
        flex-direction: column-reverse;
    }

    .OrderDetail {
        padding: 1rem;
    }
}