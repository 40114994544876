.Footer {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    color: var(--skinny);
}

.top {
    display: flex;
    padding: 3rem 4rem;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--green);
    gap: 4rem;
}

.top h3 {
    text-align: left;
}

.top ul {
    padding-left: 1.5rem;
}

.top li {
    cursor: pointer;
}

.top>div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.top>div>div {
    width: 100%;
}

.down {
    display: flex;
    padding: 0.625rem 2rem;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background: var(--dark-green, #143A0E);
}

.down>p:nth-child(2) {
    text-align: right;
}

@media (max-width: 768px) {
    .top {
        flex-direction: column !important;
        padding: 3rem 1rem;
    }

    .down {
        padding: 0.625rem 0.85rem;
        flex-direction: column;
    }

    .down>p {
        font-size: 0.85rem;
    }
}