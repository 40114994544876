.Profile {
    display: flex;
    position: relative;
    min-height: 100vh;
}

.Profile h2 {
    width: 100%;
    text-align: left;
}

.sidebar {
    width: max(20%, 300px);
    background: var(--dark-green);
    position: sticky;
    display: flex;
    padding: 1rem;
    flex-direction: column;
    gap: 1rem;
    top: 0;
}

.main {
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.main>div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.main>div img {
    aspect-ratio: 1 / 1;
    border-radius: 100%;
    height: 8rem;
    cursor: pointer;
}

.main>div:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
}

.main form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.main>div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
}

.address {
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
    height: 100%;
    width: 100%;
}

@media (max-width: 768px) {
    .sidebar {
        display: none;
    }

    .main {
        padding: 1rem;
    }
}