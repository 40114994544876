.ProductCart {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
    border: 4px solid var(--dark-green);
    width: 100%;
    border-radius: 2.25rem;
    overflow: hidden;
    min-height: 60vh;
}

.SingleProduct {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    border-bottom: 4px solid var(--dark-green);
}

.SingleProduct img {
    width: 6rem;
    border-radius: 1rems;
}

.SingleProduct>div:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-grow: 1;
}

.SingleProduct h2 {
    font-weight: 1.15rem;
    font-weight: 700;
    height: 100%;
}

.SingleProduct h3 {
    font-size: 1rem;
    font-weight: 400;
}

.SingleProduct>div:nth-child(3) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    height: 6rem;
    font-size: 0.85rem;
}

.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0;
    height: 2.25rem
}

.buttons>button:nth-child(1) {
    border-radius: 6.5rem 0 0 6.5rem;
    height: 100%;
}

.buttons>div:nth-child(2) {
    border-radius: 0;
    border: none;
    background-color: var(--dark-green);
    padding: 0 0.5rem;
    height: 100%;
    font-weight: 700;
    font-size: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttons>div:nth-child(2):hover {
    color: var(--skinny);
}

.buttons>button:nth-child(3) {
    border-radius: 0 6.5rem 6.5rem 0;
    height: 100%;
}

.SingleProduct h2>span {
    color: red;
    font-weight: 700;
    text-decoration: line-through;
    font-size: 1rem;
}

.empty {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
    flex-grow: 1;
    padding: 1rem;
    width: 100%;
}

.empty h2 {
    text-align: center;
}

@media (max-width: 768px) {
    .SingleProduct {
        width: 100%;
        flex-direction: column;
    }

    .SingleProduct img {
        width: 100%;
        border-radius: 1rems;
    }

    .SingleProduct>div:nth-child(2) {
        width: 100%;
    }

    .SingleProduct>div:nth-child(3) {
        width: 100%;
        height: auto;
        flex-direction: row;
        justify-content: space-between;
    }
}