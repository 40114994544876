/* Product */
.ProductSlider {
    display: flex;
    overflow: hidden;
    padding: 2rem 4rem 4rem 4rem;
    border-top: 4px dashed var(--dark-green, #143A0E);
    background: var(--skinny, #F4F8CF);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
}

.ProductSlider>p {
    color: var(--dark-green, #143A0E);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: justify;
}
.ProductSlider>div{
    overflow: hidden;
    border: 2px solid var(--dark-green);
    border-radius: 1.5rem;
    align-self: stretch;
}
.ProductSlider>div>div {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: flex-start;
    overflow: auto;
    padding: 1rem;
}

.ProductSlider button {
    width: 100%;
}

@media (max-width: 768px) {
    .ProductSlider {
        padding: 0.5rem;
    }
    .ProductSlider>div>div {
        gap: 0.5rem;
        padding: 0.5rem;
    }
}