.Product {
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Product>div {
    background-color: var(--green);
    padding: 1rem;
    border-radius: 1rem;
    color: var(--skinny);
    font-weight: 400;
    max-width: 600px;
    flex-grow: 1;
    width: 100%;
}

.Product form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

.Product form div {
    width: 100%;
}

.details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
}

.details>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.details>div>div {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--green);
    overflow: hidden;
    border-radius: 6rem;
}

.details p {
    width: 90%;
    background-color: var(--dark-green);
    color: var(--skinny);
    height: 100%;
    flex-grow: 1;
    padding: 0.5rem 1rem;
    border-radius: 6.5rem 0 0 6.5rem;
    font-weight: 700;
    white-space: nowrap;
    overflow: auto;
}

.details>div button {
    border-radius: 0 6.25rem 6.25rem 0;
    width: 10%;
    height: 100%;
    width: fit-content;
}

.imageSection {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
}

.imageSection>div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.imageSection h2 {
    color: var(--skinny);
}

.tempImage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
    background-color: var(--dark-green);
    cursor: pointer;
    border-radius: 1rem;
}

.imageSection input {
    display: none;
}

.imageContainer {
    width: 100%;
    border-radius: 1rem;
    position: relative;
    overflow: hidden;
    max-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageContainer>img {
    border-radius: 1rem;
}

.imageContainer>svg {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 2rem;
    color: var(--green);
    cursor: pointer;
    background-color: var(--skinny);
    border-radius: 100%;
}

.ManageProduct {
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ManageProduct>div {
    display: flex;
    padding: 0.5rem;
    border-radius: 1rem;
    background: var(--dark-green);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    max-width: 600px;
    flex-grow: 1;
    width: 100%;
}

.ManageProduct button {
    width: 100%;
}

.ManageProduct h1 {
    color: var(--skinny);
    font-size: 2rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.ManageProduct>div>div {
    padding: 0 0.25rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    flex-grow: 1;
}

.ManageProduct>div>div>div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;
}

.allProduct {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding: 0.25rem;
    background-color: #FFF;
    border-radius: 1rem;
    flex-grow: 1;
}

.singleProduct {
    display: flex;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    background: var(--green);
    color: #FFF;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.15rem;
    align-self: stretch;
    cursor: pointer;
    transition: all 200ms;
}

.singleProduct:hover {
    background-color: var(--skinny);
    color: var(--green);
}

.singleProduct h2 {
    font-size: 1.15rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    max-width: 80%;
}

.singleProduct div {
    width: 100%;
    display: flex;
    align-items: center;
}

.singleProduct>div:nth-child(1) {
    justify-content: space-between;
}

.singleProduct>div:nth-child(2) {
    gap: 1rem;
}

.singleProduct h3 {
    font-size: 0.85rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.singleProduct h4 {
    font-size: 0.85rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.singleOrder {
    display: flex;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    background: var(--green);
    color: #FFF;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.15rem;
    align-self: stretch;
    cursor: pointer;
    transition: all 200ms;
}

.singleOrder:hover {
    background-color: var(--skinny);
    color: var(--green);
}

.singleOrder h2 {
    font-size: 1.15rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    max-width: 80%;
}

.singleOrder div {
    width: 100%;
    display: flex;
    align-items: center;
}

.singleOrder>div:nth-child(1) {
    justify-content: space-between;
}

.singleOrder>div:nth-child(2) {
    gap: 0.5rem;
    border: 2px solid var(--dark-green);
    border-radius: 6.5rem;
    overflow: hidden;
}

.singleOrder>div:nth-child(2)>button {
    border-radius: 0;
    border: 0
}

/* Detail Order */
.Order {
    background-color: var(--skinny);
    padding: 2rem;
}

.Order>h3 {
    font-size: 2rem;
    color: var(--dark-green);
}

.DetailOrder {
    background-color: var(--skinny);
    padding: 2rem;
}

.DetailOrder>h3 {
    font-size: 2rem;
    color: var(--dark-green);
}

.Order>div {
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-top: 1rem;
    padding: 0 2rem;
}

.DetailOrder>div {
    width: 100%;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1.5rem;
    border: 4px solid var(--dark-green);
    min-height: 50vh;
}

.imagePart {
    display: flex;
    flex-direction: column;
    border: 4px solid var(--dark-green);
    border-radius: 1.5rem;
    overflow: hidden;
    gap: 1rem;
    width: 100%;
    justify-content: space-between;
    height: max-content;
}

.imagePart>div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6.5rem;
}

.imagePart>div:nth-child(2){
    border: 4px solid var(--dark-green);
    border-radius: 5rem;
    overflow: hidden;
}

.imagePart>div>button {
    width: 100%;
    height: 100%;
    border-radius: 0;
    border: none;
}


.DetailOrder ul {
    margin-left: 1.5rem;
}

.DetailOrder li {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    gap: 1rem;
}

.DetailOrder>div h3 {
    width: 150px;
}

.DetailOrder>div h4 {
    width: calc(100% - 150px);
}

.DetailOrder>div>div {
    width: 100%;
    display: flex;
    gap: 1rem;
}

.detailPart {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .ManageProduct {
        padding: 0.5rem;
    }
    .Order>div {
        flex-direction: column;
        padding: 1rem;
    }
    .Product {
        padding: 0.5rem;
    }

    .Order {
        padding: 1rem;
    }

    .DetailOrder>div {
        flex-direction: column;
        padding: 1rem;
    }

    .DetailOrder>div>div {
        flex-direction: column-reverse;
    }

    .DetailOrder {
        padding: 1rem;
    }
}