.AddressForm {
    border: 4px solid var(--dark-green);
    padding: 1rem;
    border-radius: 2rem;
    color: var(--dark-green);
    display: flex;
    flex-direction: column;
    gap: 1.15rem;
    max-width: 400px;
}

.AddressForm>form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.AddressTile {
    border: 4px solid var(--dark-green);
    border-radius: 2rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: space-between;
}

.AddressTile>div:nth-child(1) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.AddressTile>div:nth-child(2) {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}

.AddressTile>div>div {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}

.AddressTile h3 {
    width: 100%;
}

.AddressTile h4 {
    width: 100%;
}