.ProductCard {
    display: flex;
    padding: 1rem;
    border-radius: 1rem;
    background: var(--DarkGreen, #23581B);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.25rem;
    min-width: 200px;
    height: fit-content;
}

.ProductCard img {
    width: 100%;
    height: 12rem;
    object-fit: cover;
    border-radius: 1rem;
    padding: 0.5rem;
    background: var(--dark-green);
}

.ProductCard h3 {
    color: var(--skinny);
    font-size: 1.25rem;
    font-weight: 400;
    line-height: normal;
    text-align: justify;
    text-wrap: nowrap;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: -webkit-fill-available;
}

.ProductCard h4 {
    color: var(--skinny);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: justify;
}

.ProductCard span{
    font-size: 0.85rem;
    margin-left: 0.5rem;
}

.ProductCard button {
    width: 100%;
}

@media (max-width: 768px) {
    .ProductCard {
        min-width: 160px;
        padding: 0.25rem;
    }
    
    .ProductCard h3 {
        font-size: 1rem;
    }
    
    .ProductCard h4 {
        font-size: 1rem;
    }
    
}