.Pagination {
    padding-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.Pagination button {
    padding: 0.6rem 0.8rem;
    border: none;
    border-radius: 1rem;
    cursor: pointer;
    transition: all 200ms;
    font-weight: 700;
    color: var(--skinny);
    background-color: var(--dark-green);
}

.Pagination h2 {
    font-size: 1rem;
    font-weight: 600;
    color: var(--dark-green);
    cursor: pointer;
}

.Pagination p {
    padding: 0.375rem 0.5rem;
    border-radius: 1000px;
    aspect-ratio: 1 / 1;
    font-size: 1rem;
    font-weight: 600;
    background-color: var(--dark-green);
    color: var(--skinny);
    width: 2.25rem;
    height: 2.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
}