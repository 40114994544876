.Review {
    padding: 2rem 4rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: var(--dark-green);
    color: var(--skinny);
    border-top: 4px dashed var(--skinny);
    border-bottom: 4px dashed var(--skinny);
}

.Review>h3 {
    font-size: 1.5rem;
    font-weight: 700;
}

.Review>div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.allReviews {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.allReviews>div {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem;
    background: var(--skinny);
    color: var(--dark-green);
    border-radius: 1rem;
    border: 2px dashed;
    gap: 1rem;
}

.allReviews>div>img {
    border-radius: 100%;
    aspect-ratio: 1 / 1;
    max-height: 4rem;
}


@media (max-width: 768px) {
    .Review {
        padding: 2rem 1rem;
    }
}